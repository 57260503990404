<template>
    <base-page :breadcrumbs="getBreadcrumbs()" :pageIsBusy="pageIsBusy">
        <template v-slot:pageActions>
            <!-- Save (note there are two versions of the custom-button) -->
            <custom-button
                v-if="!isPersist"
                icon="Save"
                label="Save"
                split
                @click="onPersistSave(false)"
                :loading="isCommitLoading"
                :disabled="isSaveDisabledValue"
                :title="persistMessage"
            >
                <template v-slot:menuItems>
                    <b-dropdown-item :disabled="isPersist || isPersistToggleDisabledValue" @click.prevent="onPersistToggle"
                        ><custom-icon icon="Selected" :class="isPersist ? 'checked' : ''" />Auto-Save On</b-dropdown-item
                    >
                    <b-dropdown-item :disabled="!isPersist || isPersistToggleDisabledValue" @click.prevent="onPersistToggle"
                        ><custom-icon icon="Selected" :class="!isPersist ? 'checked' : ''" />Auto-Save Off</b-dropdown-item
                    >
                </template>
            </custom-button>
            <custom-button
                v-else
                :icon="isPersist ? 'Refresh' : 'Save'"
                label="Save"
                split
                @click="save"
                :loading="isSaveLoading"
                :disabled="isSaveDisabledValue"
                :title="persistMessage"
            >
                <template v-slot:menuItems>
                    <b-dropdown-item :disabled="isPersist || isPersistToggleDisabledValue" @click.prevent="onPersistToggle"
                        ><custom-icon icon="Selected" :class="isPersist ? 'checked' : ''" />Auto-Save On</b-dropdown-item
                    >
                    <b-dropdown-item :disabled="!isPersist || isPersistToggleDisabledValue" @click.prevent="onPersistToggle"
                        ><custom-icon icon="Selected" :class="!isPersist ? 'checked' : ''" />Auto-Save Off</b-dropdown-item
                    >
                </template>
            </custom-button>

            <!-- Preview -->
            <custom-button
                icon="Preview"
                label="PDF"
                title="Preview the RegCheck Report"
                :loading="loadingPreview"
                @click="defaultPreview"
                class="preview-button"
            />

             <!-- Add Disclosure -->
             <custom-button
                v-if="showAddDisclosure"
                icon="Add"
                label="Add Disclosure"
                title="Add Disclosure Details"
                @click="openDisclosureModal"
                class="preview-button"
            />

            <!-- Lock -->
            <custom-toggle-button
                id="lockButton"
                @onToggle="onLockToggle"
                on-icon="lock-fill"
                :off-icon="isLockedByOther ? 'lock-fill' : 'unlock-fill'"
                :title="lockMessage"
                v-if="useLoanLocking"
                :value="isLockedByMe"
                :disabled="isLockToggleDisabledValue"
                :backgroundColor="isLockedByOther ? '#ffaa34' : null"
            />
        </template>
        <slot />
        <template v-slot:summarySidebar>
            <regcheck-loan-summary />
        </template>
        <b-modal id="persistModal" v-model="showPersistModal" @ok="onPersistSave(true)" @cancel="onPersistDiscard">
            <template v-slot:modal-title>Turn Auto-Save On</template>
            <template v-slot:default>
                <blurb type="Question">
                    <template v-slot:shortMessage>You are about to turn auto-save ON. </template>
                    <template v-slot:longMessage
                        ><p>What do you want to do with your changes?</p>
                        <ul>
                            <li>Save them and overwrite any changes made by others while you had auto-save turned off.</li>
                            <li>Discard them and start with the version most recently saved by others?</li>
                        </ul>
                    </template>
                </blurb>
            </template>
            <template #modal-footer="{ ok, cancel, close }">
                <custom-button variant="secondary" @click="close()" label="Leave Auto-Save Off" />
                <custom-button variant="danger" @click="cancel()" label="Discard My Changes" />
                <custom-button @click="ok()" label="Save My Changes" />
            </template>
        </b-modal>
        <pdf-preview-modal id="preview-modal" v-model="showPreviewModal" title="RegCheck Report Preview" :src="previewDocumentUrl" />
        <new-trid-disclosure-modal id="new-disclosure-modal" v-model="showDisclosureModal" />
        <confirmation-modal id="overwriteModal" title="Save Changes" v-model="showOverwriteModal" @ok="onCommit" ok-title="Save">
            <template v-slot:shortMessage>Are you sure you want to save changes to this loan?</template>
            <template v-slot:longMessage
                >Since this loan is not locked, someone else could have made changes to this loan, and their changes will be lost.</template
            >
        </confirmation-modal>
        <confirmation-modal id="lockedModal" title="Locked" v-model="showLockedModal" messageType="Info" ok-only ok-title="Continue">
            <template v-slot:shortMessage>This loan is locked.</template>
            <template v-slot:longMessage>This loan has been locked by {{ lockedByName }}. You will not be able to save any changes.</template>
        </confirmation-modal>
        <confirmation-modal
            id="forceLockModal"
            title="Locked"
            v-model="showForceLockModal"
            @ok="onForceLock"
            ok-title="Confirm"
            ok-variant="danger"
        >
            <template v-slot:shortMessage>Take the lock from {{ lockedByName }}?</template>
            <template v-slot:longMessage
                ><p>This loan was locked by {{ lockedByName }} at {{ lockedTime }}. Are you sure you want to remove their lock and lock it yourself?</p>
                You will lose any changes you have not saved.</template
            >
        </confirmation-modal>
        <confirmation-modal id="afterLockModal" title="Locked" v-model="showLockForcedModal" messageType="Info" ok-only ok-title="Continue">
            <template v-slot:shortMessage>You no longer have a lock on this loan.</template>
            <template v-slot:longMessage>The lock on this loan has been taken by {{ lockedByName }}. Changes you make will no longer be saved.</template>
        </confirmation-modal>
        <b-modal
            id="lenderSelectModal"
            v-model="showLenderSelectModal"
            title="Missing Data"
            @ok="onLenderSave()"
            ok-title="Save"
            :ok-disabled="!isLenderSelected"
            @cancel="quit()"
            @close="quit()"
            size="md"
            :scrollable="false"
        >
            <template v-slot:default>
                <p>Please provide the following data before editing any other information in this loan:</p>
                <div class="field-section">
                    <fg-select
                        id="lenderProfileSelect"
                        label="Lender Profile"
                        :showEmptyOption="false"
                        :source="availableLenderProfiles"
                        :useLocalSearch="true"
                        :searchable="true"
                        v-model="lender.info.lenderProfileId"
                        size="xl"
                    />
                    <fg-checkbox
                        id="updateLenderProfileCheckbox"
                        v-model="updateLenderDefaults"
                        label="Update this loan's data with the lender profile's defaults"
                    />
                </div>
            </template>
        </b-modal>
    </base-page>
</template>

<script setup lang="ts">
import { defineProps, ref, watch, computed, onBeforeUnmount } from 'vue'
import { config } from '@/config'
import RegcheckLoanSummary from '@/regcheck/views/loans/regcheck-loan-summary.vue'
import { BreadcrumbItem } from '@/common/components/navigation/breadcrumb-item'
import {
    LoanLockRequest,
    LoanPersistRequest,
    UpdateLenderProfileRequest,
    LenderProfileContactDefaultType,
    MortgageType,
    LoanRequestOptions,
} from '@/common/models'
import { loanService, messageService, userPermissionValidatorService } from '@/common/services'
import { dateTimeDiffFilter } from '@/common/filters'
import { useRoute, useRouter } from 'vue-router/composables'
import { useLenderSelect } from './regcheck-lender-select-composable'
import { featureFlagService } from '@/common/services/feature-flag-service'
import NewTridDisclosureModal from './new-trid-disclosure-modal.vue'
import { storeToRefs } from 'pinia'
import { useClientStore, useCurrentUserStore, useLoanStore, usePageActionsStore, useSideNavStore } from '@/common/store'

//#region DEFINE VARIABLES
const loanRequestOptions = { includeCd: false, includeHoepaCheck: false, includeCompliance: true } as LoanRequestOptions
const props = defineProps({
    onSave: { type: Function },
    isSaveDisabled: { default: false, type: Boolean }
})

const interval = ref<number|undefined>(undefined)
const showLenderSelectModal = ref(false)
const showForceLockModal = ref(false)
const showPersistModal = ref(false)
const showDisclosureModal = ref(false)
const canShowDisclosure = ref(false)
const showOverwriteModal = ref(false)
const persistAfterSave = ref(false)
const updateLenderDefaults = ref(true)

const showPreviewModal = ref(false)
const loadingPreview = ref(false)
const previewDocumentUrl = ref('')

const route = useRoute()
const router = useRouter()
const { selectedSideNavMenuItem } = storeToRefs(useSideNavStore())
const userStore = useCurrentUserStore()
const pageActionStore = usePageActionsStore()
const clientStore = useClientStore()
const store = useLoanStore()
//#endregion

//#region COMPUTED
const client = computed(() => clientStore.initializedClient)
const loanRecord = computed(() => store.loanRecord)
const isLenderSelected = computed(() => lender.value?.info?.lenderProfileId != null)
const isCommitLoading = computed(() => store.cacheCommitLoading)

const isPersist = computed(() => store.persistGetter)
const persistMessage = computed(() => isPersist.value 
? '<div><b>Auto-save is ON</b></div><div>Loan data will be saved automatically as you make changes, but you can also save manually at any time.</div>' 
: '<div><b>Auto-save is OFF</b></div><div>Loan data will be saved only when you click Save.</div>'
)
const isPersistToggleDisabledValue = computed(() => isCommitLoading.value || isSaveLoading.value || isLockedByOther.value)
const isLockToggleDisabledValue = computed(() => isCommitLoading.value || isSaveLoading.value)
const isLockToggleDisabledByAdmin = computed(() => !client?.value?.loanLock?.value?.enable)
const useLoanLocking = computed(() => !isLockToggleDisabledByAdmin.value)

const currentUser = computed(() => userStore.currentUser?.emailAddress)

const isLocked = computed(() => store.isLocked)
const lockedByName = computed(() => store.lockedByName)
const isLockedByMe = computed(() => store.isLockedByMe)
const isLockedByOther = computed(() => store.isLockedByOther)
const lockedTime = computed(() => store.lockedTime)
const showLockedModal = computed({
    get() {
        return store.showLockedModal
    },
    set(show: boolean) {
        store.showLockedModal = show
    }
})
const showLockForcedModal = computed({
    get() {
        return store.showLockForcedModal
    },
    set(show: boolean) {
        store.showLockForcedModal = show
    }
})

const isSaveDisabledValue = computed(() => props.isSaveDisabled || isSaveLoading.value || isLockedByOther.value)
const isSaveLoading = computed(() => pageActionStore.saveLoading.isLoading)
const pageIsBusy = computed(() => pageActionStore.pageLoading.isLoading)
const lockMessage = computed(() => {
    return isLockedByMe.value
        ? '<div><b>This loan is LOCKED by you</b></div><div>You are the only one who can save changes.</div>'
        : isLockedByOther.value
        ? `<div><b>This loan is LOCKED by ${lockedByName.value}</b></div><div><b>${lockedTime.value}</b></div><div>You cannot save changes.</div><div>Click to lock the loan yourself.</div>`
        : "<div><b>This loan is NOT locked</b></div><div>Someone else's changes may overwrite yours.</div>"
})
const showAddDisclosure = computed(() => canShowDisclosure.value && loanRecord.value?.data?.terms?.mortgageType !== MortgageType.HELOC)
//#endregion

//#region WATCH
watch(route, () => {
    const readPermission: string = route?.meta?.readPermission
    if (readPermission) {
        const hasPermission = userPermissionValidatorService.hasAnyPermission([readPermission])
        if (!hasPermission) {
            router.push({ name: 'unauthorized' })
        }
    }
})
watch(lockedByName, async (_: string | undefined, previous: string | undefined) => {
    if (isLockedByOther.value) {
        if (previous === undefined) {
            showLockedModal.value = true
        } 
        //was unlocked, but is now locked by someone else
        else if (previous === currentUser.value) {
            showLockForcedModal.value = true //was locked by me, but is now locked by someone else
        }
        await fixPersist()
    }
})
//#endregion

//#region INITIALIZE
const {
    lender,
    availableLenderProfiles,
    initializeLender
} = useLenderSelect()

initialize()

onBeforeUnmount(() => clearInterval(interval.value))

function initialize() {
    interval.value = setInterval(async () => {
            if (config.app.pingInterval <= 0) {
                //setting the interval to 0 disables this feature
                clearInterval(interval.value)
                return
            }
            await store.ping()
        }, config.app.pingInterval * 1000)

    pageActionStore.setBusyPage(true) // set page to busy until after async initialization is complete
    store.ping()
        .then(() => fixPersist())
        .then(() => initializeLender(loanRecord.value?.data?.lender))
        .then(() => {
            checkForLender()
            checkForHasShownLockedModal()
            checkForDisclosureFeature()
        })
        .finally(() => pageActionStore.setBusyPage(false))
}
//#endregion

async function onCommit() {
    store.cacheCommitLoading = true
    await save()
    await store.commitLoan()
    if (persistAfterSave) {
        await onPersistDiscard()
    }
    persistAfterSave.value = false
    store.cacheCommitLoading = false
}

async function onPersistToggle() {
    if (isPersist.value) {
        await store.setLoanPersist({ requestCachedId: true, ...loanRequestOptions } as LoanPersistRequest)
    } else {
        showPersistModal.value = true
    }
}

async function onPersistSave(persistAfter: boolean) {
    if (!isLockedByMe.value) {
        showOverwriteModal.value = true
    } else {
        await onCommit()
    }
    persistAfterSave.value = persistAfter
}

async function onPersistDiscard() {
    await store.setLoanPersist({ requestCachedId: false, updateLoanRecord: true, ...loanRequestOptions } as LoanPersistRequest)
}

async function fixPersist() {
    if (isLockedByOther.value && isPersist.value){
        //not allowed to persist if the loan is locked by someone else
        await store.setLoanPersist({ requestCachedId: true, ...loanRequestOptions } as LoanPersistRequest)
    }
}

async function checkForDisclosureFeature() {
    const isDisclosureEnabled = await featureFlagService.isFeatureEnabled("trid-disclosure", store.clientCode)
    canShowDisclosure.value = isDisclosureEnabled
}

function checkForLender() {
    showLenderSelectModal.value = !lender?.value?.info?.lenderProfileId
}

async function onLenderSave() {
    await store.updateLenderProfile({
        lenderProfileId: lender.value.info.lenderProfileId,
        defaultType: updateLenderDefaults ? LenderProfileContactDefaultType.Initial : LenderProfileContactDefaultType.None,
        includeCompliance: true
    } as UpdateLenderProfileRequest)
}

async function onLockToggle() {
    if (isLockedByOther.value) {
        showForceLockModal.value = true
    } else {
        await store.setLoanLock({ lock: !isLocked.value } as LoanLockRequest)
    } 
}

async function onForceLock() {
    await store.setLoanLock({ lock: true, force: true } as LoanLockRequest)
    //auto-save is already off, but we want the endpoint to give us a fresh cached copy
    await store.setLoanPersist({ requestCachedId: true, updateLoanRecord: true, ...loanRequestOptions } as LoanPersistRequest)
}

function checkForHasShownLockedModal() {
    if (isLockedByOther.value && !store.hasShownLockedModal) {
        store.hasShownLockedModal = true
        showLockedModal.value = true
    } else {
        showLockedModal.value = false
    }
}

function openDisclosureModal() {
    showDisclosureModal.value = true
}

async function defaultPreview() {
    loadingPreview.value = true
    previewDocumentUrl.value = ''
    try { 
        const pdfUri = await loanService.getCompliancePdfUri(store.loanRecord?.id ?? '')
        previewDocumentUrl.value = pdfUri
        showPreviewModal.value = true
    } catch {
        messageService.showSystemError('RegCheck encountered an unexpected error while generating your PDF report: please contact support for assistance.', 'System Error')
    } finally {
        loadingPreview.value = false
    }
}

function getBreadcrumbs(): BreadcrumbItem[] {
    return [
        { text: 'Loans', routeName: 'loan-grid', routeQuery: store.loanGridQuery },
        { text: store.loanRecord?.loanNumber ?? '' },
        { text: selectedSideNavMenuItem.value?.name ?? '' }
    ]
}

function quit() {
    //go back to loan grid with the stored query
    router.push({ name: 'loan-grid', query: store.loanGridQuery })
}

async function save () {
    if (props.onSave){
        await props.onSave()
    } 
}
</script>

<style scoped lang="scss">
:deep() .modal-xl .modal-body {
    height: 70vh !important;
}

.page-actions .dropdown-item {
    .custom-icon.selected {
        font-size: 0.75rem;

        &:not(.checked) {
            visibility: hidden;
        }
    }
}

.page-spinner {
    position: initial !important;
}

:deep() .preview-button {
    button:not(.dropdown-toggle) {
        min-width: 5.25rem;
    }
}

.btn-group.focus,
.btn-group:focus {
    outline: 0;
    box-shadow: none !important;
}
</style>