import { 
    Borrower, 
    BorrowerInformation, 
    BorrowerEmployment,
    BorrowerPreviousEmployment,
    Address,
    BorrowerCurrentAddress,
    BorrowerFinancialInformation,
    BorrowerFinancialDeclarations,
    BorrowerLoanDeclarations,
    URLA,
    DemographicInformation,
    BorrowerUnmarriedAddendum,
    Race,
    BorrowerEthnicity,
    BorrowerAccount,
    BorrowerGender,
    BorrowerAsset,
    BorrowerLiability,
    BorrowerExpense,
    CreditScoreDisclosure,
    Overridable,
    Guarantor,
    GuarantorSpouse,
    BorrowerType,
    BorrowerIncomeSources,
    SupplementalConsumerInfoProgram,
    BorrowerGift,
    BorrowerProperty,
    UrlaFormType,
} from "@/common/models"
import { cloneDeep } from "lodash"

export interface BorrowerViewModel extends Omit<Borrower, 'information'> {
    information: BorrowerInformationViewModel
    spouse: GuarantorSpouse
}

export interface BorrowerInformationViewModel extends BorrowerInformation {    
    employmentHistory: EmploymentHistory[]
}

export interface EmploymentHistory extends BorrowerPreviousEmployment{
    type: EmploymentType    
}

export enum EmploymentType{
    Current = "Current",
    Previous = "Previous"
}

class BorrowerViewModelConverter {
    convertToViewModel(model: Borrower | Guarantor, mailingAddressIsOverridden = false): BorrowerViewModel {
        const borrower = cloneDeep(model) as BorrowerViewModel

        borrower.mailingAddress = borrower.mailingAddress || ({} as Overridable<Address>)
        if (mailingAddressIsOverridden) borrower.mailingAddress.isOverridden = true
        borrower.mailingAddress.value = borrower.mailingAddress.value || ({} as Address)
        borrower.currentAddress = borrower.currentAddress || ({} as BorrowerCurrentAddress)
        borrower.previousAddresses = borrower.previousAddresses ?? []
        borrower.information = borrower.information || ({} as BorrowerInformation)
        borrower.information.incomeSources = borrower.information.incomeSources ?? [] 
        borrower.information.previousEmployments = borrower.information.previousEmployments ?? []
        borrower.information.previousEmployments.forEach(work => {
            work.address = work.address || ({} as Address)
        })
        //Employment history is used to make both current and previous as one single item as the model cannot be updated.
        borrower.information.employmentHistory = []
        if (borrower.information.currentEmployment) {
            borrower.information.employmentHistory.push({ ...borrower.information.currentEmployment as EmploymentHistory, type: EmploymentType.Current })
        }
        if (borrower.information.previousEmployments && borrower.information.previousEmployments.length > 0) {
            borrower.information.previousEmployments.forEach(emp => {
                borrower.information.employmentHistory.push({ ...emp as EmploymentHistory, type: EmploymentType.Previous })
            })
        }
        if (borrower.information.additionalEmployments && borrower.information.additionalEmployments.length > 0) {
            borrower.information.additionalEmployments.forEach(emp => {
                borrower.information.employmentHistory.push({ ...emp as EmploymentHistory, type: EmploymentType.Current })
            })
        }
        
        borrower.financialInformation = borrower.financialInformation || ({} as BorrowerFinancialInformation)
        borrower.financialInformation.properties = borrower.financialInformation.properties ?? []
        borrower.aliases = borrower.aliases ?? []
        borrower.creditScoreDisclosures = borrower.creditScoreDisclosures || [] as CreditScoreDisclosure[]
        borrower.creditScoreDisclosures.forEach(i => i.keyFactors = i.keyFactors ? i.keyFactors : [])
        if (borrower.isCorp && borrower.corporation) {
            borrower.corporation.address = borrower.corporation.address || ({ street: '', unit: '', city: '', state: '', zip: '' } as Address)
            borrower.corporation.agents = borrower.corporation.agents || [];
        }
        else {
            borrower.corporation = null
        }
        borrower.demographicInformation = borrower.demographicInformation || ({race: {} as Race, ethnicity: {} as BorrowerEthnicity, gender: {} as BorrowerGender} as DemographicInformation)
        borrower.demographicInformation.race = borrower.demographicInformation.race || ({} as Race)
        borrower.demographicInformation.ethnicity = borrower.demographicInformation.ethnicity || ({} as BorrowerEthnicity)
        borrower.demographicInformation.gender = borrower.demographicInformation.gender || ({} as BorrowerGender)
        borrower.loanDeclarations = borrower.loanDeclarations || ({} as BorrowerLoanDeclarations)
        borrower.financialInformation = borrower.financialInformation ?? ({} as BorrowerFinancialInformation)
        borrower.financialDeclarations = borrower.financialDeclarations || ({} as BorrowerFinancialDeclarations)

        borrower.financialInformation.accounts = borrower.financialInformation.accounts ?? [] as BorrowerAccount[]
        borrower.financialInformation.assets = borrower.financialInformation.assets ?? [] as BorrowerAsset[]
        borrower.financialInformation.liabilities = borrower.financialInformation.liabilities ?? [] as BorrowerLiability[]
        borrower.financialInformation.expenses = borrower.financialInformation.expenses ?? [] as BorrowerExpense[]
        borrower.financialInformation.gifts = borrower.financialInformation.gifts ?? []
        
        borrower.unmarriedAddendum = borrower.unmarriedAddendum || {} as BorrowerUnmarriedAddendum
        borrower.unmarriedAddendum.hasRealPropertyRights ??= false
        borrower.urla = borrower.urla || {} as URLA 
        borrower.urla.formType ??= UrlaFormType.None
        borrower.spouse = borrower.spouse || {} as GuarantorSpouse
        borrower.spouse.address = borrower.spouse.address || ({ street: '', unit: '', city: '', state: '', zip: '', county: '' } as Address)

        return borrower
    }

    convertToModel(viewModel: BorrowerViewModel): Borrower {
        function convertEmploymentHistoryToModel(history: EmploymentHistory[]): BorrowerEmployment[] {
            return history.map(h => Object({
                ...h,
                type: undefined
            }))
        }

        return Object({
            ...viewModel,
            information: Object({
                ...viewModel.information,
                currentEmployment: convertEmploymentHistoryToModel(viewModel.information.employmentHistory.filter(emp => emp.type == EmploymentType.Current))[0],
                additionalEmployments: convertEmploymentHistoryToModel(viewModel.information.employmentHistory.filter(emp => emp.type == EmploymentType.Current))?.slice(1),
                previousEmployments: convertEmploymentHistoryToModel(viewModel.information.employmentHistory.filter(emp => emp.type == EmploymentType.Previous)),
                employmentHistory: undefined
            })
        })
    }
}

export const borrowerViewModelConverter = new BorrowerViewModelConverter()

export function getEmptyBorrower(allBorrowers: BorrowerViewModel[], borrowerType: BorrowerType, isReleaseOfLien: boolean) {
    const borrower =  {
        firstName: '',
        lastName: '',
        type: borrowerType,
        id: getNewBorrowerId(allBorrowers),
        creditScoreDisclosures: [] as CreditScoreDisclosure[],
        powerOfAttorneyTitle: { isOverridden: false, value: '' },
        currentAddress: {
            street: '',
            unit: '',
            city: '',
            state: '',
            zip: '',
            county: ''
        },
        previousAddresses: [] as BorrowerCurrentAddress[],
        mailingAddress: {
            isOverridden: isReleaseOfLien, //release of lien mode mailing addresses should always be overridden
            value: {
                street: '',
                unit: '',
                city: '',
                state: '',
                zip: '',
                county: ''
            }
        },
        corporation: null,
        urla: { linkedBorrowerId: 0 } as URLA,
        demographicInformation: {
            race: {} as Race, 
            ethnicity: {} as BorrowerEthnicity, 
            gender: {} as BorrowerGender 
        },
        information: {
            employmentHistory: [] as EmploymentHistory[],
            incomeSources: [] as BorrowerIncomeSources[]
        },
        loanDeclarations: {} as BorrowerLoanDeclarations,
        financialDeclarations: {} as BorrowerFinancialDeclarations,
        unmarriedAddendum: {} as BorrowerUnmarriedAddendum,
        financialInformation : {
            accounts: [] as BorrowerAccount[],
            assets: []  as BorrowerAsset[],
            liabilities: [] as BorrowerLiability[],
            expenses: [] as BorrowerExpense[],
            gifts: [] as BorrowerGift[],
            properties: [] as BorrowerProperty[]
        },
        supplementalConsumerInfo: { 
            education: {} as SupplementalConsumerInfoProgram,
            counseling: {} as SupplementalConsumerInfoProgram
        },
        aliases: [] as string[]
    } as BorrowerViewModel

    if (borrowerType === BorrowerType.Guarantor) {
        borrower.spouse = {
            firstName: '',
            lastName: '',
            middleName: '',
            suffixName: '',
            address: {
                street: '',
                unit: '',
                city: '',
                state: '',
                zip: '',
                county: ''
            } as Address
        } as GuarantorSpouse

        borrower.urla.formType = UrlaFormType.None
    }

    return borrower
}

function getNewBorrowerId(borrowers: BorrowerViewModel[]): number {
    const usedIds = borrowers.map(borrower => borrower.id)
    let newId = generateId()
    while (usedIds.includes(newId)) {
        newId = generateId()
    }
    return newId
}

function generateId(min = 100, max = 999) {
    return Math.floor(Math.random() * (max - min)) + min
}