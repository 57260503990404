export enum ClientDeliveryOption {
    Save = "Save",
    LoanReview = "LoanReview",
    DocuSign = "DocuSign",
    NotaryCam = "NotaryCam",
    Email = "Email",
    SaveDraft = "SaveDraft",
    Fulfillment = "Fulfillment"
}

export interface NewPackageSaveOption {
    displayText: string
    description: string
    value: ClientDeliveryOption
    buttonText: string
    draftLabelText: string
}