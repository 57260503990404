import { MultiSelectItem } from '@/common/components/multi-select-item'
import { EmailLinkType, ClientDeliveryOption, EmailBranding, NotaryCam, DocuSign } from '@/common/models'

export interface PackageDeliveryRequest {
    packageId: string
    delivery: Delivery    
}

export interface Delivery {
    docuSign: DocuSign
    email: Email
    loanReview: DeliveryLoanReview
    notaryCam: NotaryCam
}

export interface DeliveryLoanReview {
    isEnabled: boolean
}

export interface DocusignSecurity {
    ccRecipientAccessCode: string
}

export interface DocusignOptions {
    deadlines: []
}

export interface CcRecipient {
    fullName: string
    email: string
}

//todo: this model doesnt match what is in the admindata api. Why?
//present in api missing in email model: fromEmailType, recipientList
export interface Email {
    isEnabled: boolean
    templateId: string
    from: EmailDetails
    emailTo: string[]
    emailLinkType: EmailLinkType
    security: SecurityOptions
    branding: EmailBranding

    //these data points are used by the email delivery service
    loanData: EmailLoanData
    emailCc: string[]
    emailMessage: string | null
}

export interface EmailLoanData {
    lenderName: string
    borrowerName: string
    borrowerFirstName: string
    borrowerLastName: string
    packageName: string
    titleFileNumber: string
    loanLenderName: string
}

export interface SecurityOptions {
    expiration: number
    minimumSecurityRequired: number
    systemPassword: boolean
    loanDetails: LoanDetails
}

export interface LoanDetails {
    loanNumber: string | null
    propertyStreetNumber: string | null
    propertyZipCode: string | null
    borrowerSSN: string | null
    borrowerBirthDate: string | null
}

export interface EmailDetails {
    displayName: string
    emailAddress: string
}

export interface PackageDeliveryResponse {
    status: PackageDeliveryStatus
    message: string
    messageTitle: string
}

export interface TransactionSearchResult {
    id: string
    fileId: string
    borrowerLastName: string
    clientCode: string
    closingDate: Date
    dateCreated: Date
    description: string
    lenderCaseNumber: string
    loanNumber: string
    orderNumber: string
    partnerCode: string
    recipients: string[]
    vendor: TransactionVendor
}

export interface TransactionVendor {
    status: string
    transactionId: string
    type: string
}

export interface EmailTransactionRequest {
    clientCode: string
    loanNumber: string
}

export interface LastEmailTransaction {
    clientCode: string
    loanNumber: string
    to: string[] | null
    cc: string[] | null
    emailLinkType: EmailLinkType
}

export interface EmailRecipient {
    type?: string
    name?: string
    phone?: string
    email: string
}

export interface AutoCompleteSuggestions {
    data: any[]
}

export interface AutoCompleteItem {
    item: EmailRecipient
}

export interface LoanReviewRequest {
    clientCode: string
    clientName: string
    userName: string
    userEmail: string
    loanReviewEmail: string
    loanId: string
    loanNumber: string
    packageType: string
    borrowers: string[]
    propertyState: string
    message: string
    files: FileInfo[]
    primaryBorrower: string
}

export interface ImportJsonRequest {
    message: MultiSelectItem[]
    file: FileInfo
}

export interface FileInfo {
    fileName: string
    dataUrl: string | undefined
}

export interface LoanReviewResponse {
    succeeded: boolean
    message: string
}

export interface ClientDeliveryMethod {
    clientDeliveryOptions: Map<ClientDeliveryOption, ClientDeliveryDocument>
    loanReviewIsRequired: boolean
}

export interface ClientDeliveryDocument {
    allowedDocuments: ClientAllowedDocument[]
}

export enum PackageDeliveryStatus {
    Success = 'Success',
    Error = 'Error',
    SystemError = 'SystemError'
}

export enum ClientAllowedDocument {
    None = "None",
    Draft = "Draft",
    Final = "Final"
}