import { Address, CreditAgency, Overridable } from './index'

export interface Borrower extends Name {
    id: number
    type: BorrowerType
    unparsedName: string
    ssn: string
    dateOfBirth?: Date
    maritalStatus: string
    emailAddress: string
    homePhone: string
    cellPhone: string
    workPhone: string
    workPhoneExt: string
    mailingAddress: Overridable<Address>
    currentAddress: BorrowerCurrentAddress
    previousAddresses: BorrowerCurrentAddress[]
    information: BorrowerInformation
    financialInformation: BorrowerFinancialInformation
    supplementalConsumerInfo: BorrowerSupplementalConsumerInfo
    financialDeclarations: BorrowerFinancialDeclarations
    loanDeclarations: BorrowerLoanDeclarations
    aliases: string[]
    relationshipCode: string
    powerOfAttorneyName: string
    powerOfAttorneyTitle: Overridable<string>
    creditScoreDisclosures: CreditScoreDisclosure[]
    aka: string
    signature: string
    isCorp: boolean
    corporation: BorrowerCorporation | null
    urla: URLA
    demographicInformation: DemographicInformation
    unmarriedAddendum: BorrowerUnmarriedAddendum
}

export interface Guarantor extends Borrower {
    spouse: GuarantorSpouse
}

export interface GuarantorSpouse extends Name {
    address: Address
}

export interface DemographicInformation {
    race: Race
    ethnicity: BorrowerEthnicity
    gender: BorrowerGender
    isRaceObserved: boolean
    isGenderObserved: boolean
    isEthnicityObserved: boolean
    collectionMethod: string
}
export interface BorrowerFinancialInformation {
    accounts: BorrowerAccount[]
    assets: BorrowerAsset[]
    liabilities: BorrowerLiability[]
    expenses: BorrowerExpense[]
    gifts: BorrowerGift[]
    properties: BorrowerProperty[]
}

export interface BorrowerAccount {
    type: string
    financialInstitution: string
    accountNumber: string
    value?: number
}

export interface BorrowerAsset {
    type: string
    otherDescription?: string
    value?: number
}

export interface BorrowerLiability {
    type: string
    otherDescription?: string
    companyName: string
    accountNumber: string
    unpaidBalance?: number
    paidOffByClosing?: boolean
    monthlyPayment?: number
}

export interface BorrowerExpense {
    type: string
    otherDescription?: string
    monthlyPayment?: number
}

export interface BorrowerGift {
    type: string
    isDeposited: boolean
    source: string
    otherSource?: string
    value?: number
}

export interface BorrowerSupplementalConsumerInfo {
    education: SupplementalConsumerInfoProgram
    counseling: SupplementalConsumerInfoProgram
}

export interface SupplementalConsumerInfoProgram {
    isCompleted: boolean
    format: string
    agencyName: string | null
    agencyId: string | null
    completionDate: Date
}

export interface Race {
    americanIndianOrAlaskaNative: boolean
    principalTribe: string
    asian: boolean
    asianIndian: boolean
    chinese: boolean
    filipino: boolean
    japanese: boolean
    korean: boolean
    vietnamese: boolean
    otherAsian: boolean
    otherAsianRace: string
    blackOrAfricanAmerican: boolean
    nativeHawaiianOrOtherPacificIslander: boolean
    nativeHawaiian: boolean
    guamanianOrChamorro: boolean
    samoan: boolean
    otherPacificIslander: boolean
    otherPacificIslanderRace: string
    white: boolean
    notProvided: boolean
}


export interface CreditScoreDisclosure {
    currentScore: number
    scoreRangeLow: number
    scoreRangeHigh: number
    keyFactors: string[]
    createdDate: Date
    scoreRankPercentage: number
    agency: CreditScoreReportingAgency
    creditAgency: CreditAgency | null
}

export interface CreditScoreReportingAgency {
    name: string
    phone: string
    address: CreditScoreReportingAgencyAddress
}

export interface CreditScoreReportingAgencyAddress {
    street: string
    city: string
    state: string
    zip: string
}

export interface BorrowerCorporation {
    name: string
    stateOfIncorporation: string
    entityType: string
    typeOtherDescription: string
    agents: BorrowerCorporationAgent[]
    taxIdentificationNumber: number
    address: Address
}

export interface BorrowerCorporationAgent {
    id: number
    borrowerId: number | null
    name: string
    title: string
}

export interface URLA {
    linkedBorrowerId: number
    isFirstTimeHomeBuyer: boolean
    hadPriorVALoan: boolean
    formType: UrlaFormType
}

export enum UrlaFormType {
    None = 'None',
    Standard = 'Standard',
    AdditionalBorrower = 'AdditionalBorrower'
}

export interface Name {
    firstName: string
    middleName: string
    lastName: string
    suffixName: string
    fullName: string
}

export enum BorrowerType {
    Borrower = 'Borrower',
    Cosigner = 'Cosigner',
    NonObligated = 'NonObligated',
    Guarantor = 'Guarantor'
}

export enum ResidencyType {
    NoExpense = 'NoExpense',
    Own = 'Own',
    Rent = 'Rent'
}

export interface BorrowerCurrentAddress extends Address {
    years: number
    months: number
    residency: ResidencyType | null
    monthlyRent: number | null
}

export interface BorrowerInformation {
    citizenship: string
    dependentsAges: string
    dependentsCount: number | null
    creditType: string
    language: string
    languageOther: string
    currentEmployment: BorrowerEmployment
    additionalEmployments: BorrowerEmployment[]
    previousEmployments: BorrowerPreviousEmployment[]
    incomeSources: BorrowerIncomeSources[]
    hasMilitaryService: boolean
    militaryService: MilitaryServiceType | null
    militaryServiceExpirationDate: Date | null
    isSurvivingSpouse: boolean | null
}

export enum MilitaryServiceType{
    ActiveDuty = 'ActiveDuty',
    Veteran = 'Veteran',
    ReserveNationalGuardNeverActivated = 'ReserveNationalGuardNeverActivated'
}

export interface BorrowerIncomeSources{
    source: string
    monthlyIncome: number
    otherDescription: string
}

export interface BorrowerEmployment{
    employerName: string
    phone: string
    address: Address
    title: string
    startDate: Date | null
    endDate: Date | null
    yearsInLineOfWork: number | null
    monthsInLineOfWork: number | null
    ownerOrSelfEmployed: boolean | null
    employedByPartyToLoan: boolean | null
    ownershipShareOver25Percent: boolean | null
    monthlyIncomeOrLoss: number | null
    grossMonthlyIncomeBase: number
    grossMonthlyIncomeOvertime: number
    grossMonthlyIncomeBonus: number 
    grossMonthlyIncomeCommission: number 
    grossMonthlyIncomeMilitaryEntitlements: number 
    grossMonthlyIncomeOther: number
}

export interface BorrowerPreviousEmployment extends BorrowerEmployment{
    previousGrossMonthlyIncome: number | null
}

export interface BorrowerProperty{
    address: Address
    value: number
    status: string
    intendedOccupancyType: string
    monthlyDues: number
    monthlyRentalIncome: number
    netMonthlyRentalIncome: number
    loans: BorrowerPropertyLoan[]
}

export interface BorrowerPropertyLoan{
    creditor: string
    accountNumber: string
    monthlyPayment: number
    unpaidBalance: number
    paidOffByClosing: boolean
    type: string
    creditLimit: number
}

export interface BorrowerFinancialDeclarations{
    hasUndisclosedDebt: boolean
    hasOutstandingJudgments: boolean
    isDelinquent: boolean
    isPartyToLawsuit: boolean
    hasPriorDeedInLieu: boolean
    hasPriorShortSale: boolean
    hasPriorForclosure: boolean
    hasPriorBankruptcy: boolean
    priorBankruptcyTypes: string[]
}

export interface BorrowerLoanDeclarations{
    intendsToOccupy: boolean
    hasOwnedPriorProperty: boolean
    priorPropertyOccupancy: string | null
    priorTitleOwnership: string | null
    isAffiliatedWithSeller: boolean
    hasUndisclosedFunds: boolean
    undisclosedFundsAmount: number | null
    hasUndisclosedMortgageApplication: boolean
    hasUndisclosedCreditApplication: boolean
    hasOtherLien: boolean
}

export interface BorrowerUnmarriedAddendum{
    hasRealPropertyRights: boolean
    relationshipType: string | null
    otherRelationshipType: string | null
    state: string | null
}

export interface BorrowerEthnicity{
    hispanicOrLatino: boolean
    mexican: boolean
    puertoRican: boolean
    cuban: boolean
    otherHispanicOrLatino: boolean
    otherHispanicOrLatinoOrigin: string
    notHispanicOrLatino: boolean
    notProvided: boolean
}

export interface BorrowerGender{
    female: boolean
    male: boolean
    notProvided: boolean
}