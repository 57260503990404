import { defineStore } from 'pinia'
import { computed, ref, set } from 'vue'
import {
    AdverseAction,
    AdverseActionFormType,
    AutomatedUnderwriting, 
    Borrower,
    Broker,
    C1,
    C4,
    C6,
    CalculatedData,
    Cd,
    Closing,
    ClosingFunds,
    ComplianceOnlyData,
    ComplianceTestResult, 
    Counteroffer, 
    DecisionSources, 
    DenialReasons, 
    Disclosures, 
    DocumentConfig, 
    DocumentDatasets, 
    Escrow, 
    EscrowHoldback, 
    ExistingLoan,
    FannieMaeClassification, 
    Fee,
    Fha,
    FreddieMacClassification, 
    HousingExpense, 
    IncomeDetails,
    Investor, 
    LateFee, 
    Le, 
    Lender, 
    LenderProfile, 
    Loan,
    LoanCd, 
    LoanDefaultValueResult, 
    LoanEstimate, 
    LoanLockRequest, 
    LoanPersistRequest, 
    LoanRecord, 
    LoanRequestOptions,
    LoanSellerInformation, 
    Lock, 
    MaLendingStandards, 
    MortgageInformation,
    MortgageInsurance,
    MortgageOriginatorType, 
    MultiSignerNotary, 
    NewTridDisclosureRequest, 
    Originator, 
    Overridable, 
    PrepaymentPenalty, 
    PrivateMortgageInsurance, 
    Property,
    PropertyReviewLevel,
    QualifyingRate,
    QualifyingRatios, 
    RateSelection,
    ReleaseOfLien,
    RiskAssessment,
    SecurityInstrument, 
    Seller, 
    ServiceProvider, 
    SettlementAgent, 
    StateSpecific, 
    TangibleNetBenefits, 
    Terms, 
    TitleCompany, 
    TridDisclosure, 
    TridDisclosurePatchRequest, 
    Trust, 
    Underwriting,
    UnderwritingAgreement,
    UnderwritingInformation,
    UnderwritingSummary_1008, 
    UpdateDocumentModeRequest, 
    UpdateInvestorRequest, 
    UpdateLenderProfileRequest, 
    Va,
    VaLoanAnalysis,
    Vesting,
    WarrantyDeed
} from '@/common/models'
import { adminDataService, backendService, loanService, userPreferenceService } from '@/common/services'
import { DocumentMode } from '@/propel/models'
import { cloneDeep } from 'lodash'
import { tridDisclosureService } from '@/common/services/trid-disclosure-service'
import { Dictionary } from 'vue-router/types/router'
import { useAuditCheckStore, useCurrentUserStore, usePageActionsStore } from '.'
import { dateTimeDiffFilter } from '@/common/filters'

export const useLoanStore = defineStore('loan', () => {
    const pageActions = usePageActionsStore()
    const auditChecks = useAuditCheckStore()
    const userStore = useCurrentUserStore()

    //#region DEFINE VARIABLES
    // loan details
    const isProtectedDisabled = ref(false)
    const loanRecord = ref<LoanRecord | null>(null)
    const loanLock = ref<Lock | null>(null)
    const lenderProfile = ref<LenderProfile | null>(null)
    const lastBorrowerId = ref<number | null>(null)
    const overriddenDefaultPaths = ref({} as Record<string, boolean>)
    const complianceChecks = ref<ComplianceTestResult[]>([])
    const complianceErrors = ref<string[]>([])
    
    // loan page details
    const cacheCommitLoading = ref(false)
    const componentKey = ref(0)
    const hasShownLockedModal = ref(false)
    const loanGridQuery = ref<Dictionary<string | (string | null)[] | null | undefined> | null>({})
    const persist = ref<boolean | null>(null)
    const previewDocument = ref<string | null>(null)
    const showLockedModal = ref(false)
    const showLockForcedModal = ref(false)
    //#endregion

    //#region COMPUTED
    const loan = computed(() => cloneDeep(loanRecord.value?.data || {} as Loan))

    const adverseAction = computed(() => {
        const adverseAction = documentDatasets.value.adverseAction || { formType: AdverseActionFormType.None } as AdverseAction
        adverseAction.decisionSources = adverseAction.decisionSources || {} as DecisionSources
        adverseAction.maLendingStandards = adverseAction.maLendingStandards || {} as MaLendingStandards
        adverseAction.c1 = adverseAction.c1 || {} as C1
        adverseAction.c1.denialReasons = adverseAction.c1.denialReasons || {} as DenialReasons
        adverseAction.c4 = adverseAction.c4 || {} as C4
        adverseAction.c4.counteroffer = adverseAction.c4.counteroffer || {} as Counteroffer
        adverseAction.c6 = adverseAction.c6 || {} as C6
        return adverseAction
    })
    const automatedUnderwriting = computed(() => loan.value.automatedUnderwriting || {} as AutomatedUnderwriting)
    const borrowers = computed(() => loan.value.borrowers || [] as Borrower[])
    const broker = computed(() => loan.value.broker || {} as Broker)
    const calculatedCd = computed(() => cloneDeep(loanRecord.value?.cd || {} as Cd))
    const calculatedLe = computed(() => cloneDeep(loanRecord.value?.le || {} as Le))
    const cd = computed(() => disclosures.value.cd || {} as LoanCd)
    const clientCode = computed(() => loanRecord.value?.clientCode ?? '')
    const closing = computed(() => loan.value.closing || {} as Closing)
    const compliance = computed(() => {
        const complianceOnlyData = loan.value.complianceOnlyData || {} as ComplianceOnlyData
        complianceOnlyData.appraisalDeliveries = complianceOnlyData.appraisalDeliveries || []
        return complianceOnlyData
    })
    const defaultedFields = computed(() => loanRecord.value?.defaultedFields || {} as Record<string, LoanDefaultValueResult>)
    const disclosures = computed(() => documentDatasets.value.disclosures || {} as Disclosures)
    const documentDatasets = computed(() => loan.value.documentDatasets || {} as DocumentDatasets)
    const documentMode = computed(() => loanRecord.value?.documentMode)
    const fees = computed(() => loan.value.fees || [] as Fee[])
    const fha = computed(() => loan.value.fha || {} as Fha)
    const escrow = computed(() => loan.value.escrow || {} as Escrow)
    const escrowHoldback = computed(() => documentDatasets.value.escrowHoldback || {} as EscrowHoldback)
    const existingLoan = computed(() => loan.value.existingLoan || {} as ExistingLoan)
    const housingExpenses = computed(() => loan.value.housingExpenses || [])
    const guarantors = computed(() => loan.value.guarantors || [])
    const includeOnlyBorrowerFeesLe = computed(() => loan.value.includeOnlyBorrowerFeesLe ?? false )
    const investor = computed(() => loan.value.investor || {} as Investor)
    const isModification = computed(() => loanRecord.value?.documentMode === DocumentMode.Modification)
    const isReleaseOfLien = computed(() => loanRecord.value?.documentMode === DocumentMode.ReleaseOfLien)
    const lateFee = computed(() => loan.value.lateFee || {} as LateFee)
    const lender = computed(() => loan.value.lender || {} as Lender)
    const loanDocumentOptions = computed(() => loan.value.documentOptions || {} as DocumentConfig)
    const loanEstimate = computed(() => disclosures.value.le || {} as LoanEstimate)
    const loanNumber = computed(() => loanRecord.value?.loanNumber || '')
    const loanInLeMode = computed(() => loanRecord.value?.documentMode === DocumentMode.Initial)
    const loanInCdMode = computed(() => loanRecord.value?.documentMode === DocumentMode.Closing)
    const loanRecordId = computed(() => loanRecord.value?.id || '')
    const loanRecordCalculations = computed(() => {
        const calculations = cloneDeep(loanRecord.value?.calculations || {} as CalculatedData)
        calculations.pmi = calculations.pmi || {} as PrivateMortgageInsurance
        return calculations
    })
    const mortgageInsurance = computed(() => loan.value.mortgageInsurance || {} as MortgageInsurance)
    const notary = computed(() => loan.value.notary || {} as MultiSignerNotary)
    const originator = computed(() => loan.value.originator || {} as Originator)
    const prepaymentPenalty = computed(() => loan.value.prepaymentPenalty || {} as PrepaymentPenalty)
    const property = computed(() => loan.value.property || {} as Property)
    const releaseOfLien = computed(() => documentDatasets.value.releaseOfLien || [])
    const securityInstrument = computed(() => documentDatasets.value.securityInstrument || {} as SecurityInstrument)
    const seller = computed(() => loan.value.seller || {} as Seller)
    const serviceProviders = computed(() => loan.value.serviceProviders || [])
    const settlementAgent = computed(() => loan.value.settlementAgent || {} as Overridable<SettlementAgent>)
    const stateSpecific = computed(() => loan.value.stateSpecific || {} as StateSpecific)
    const tangibleNetBenefits = computed(() => loan.value.tangibleNetBenefits || {} as TangibleNetBenefits)
    const temp = computed(() => loan.value.temp || {} as Record<string, string>)
    const terms = computed(() => loan.value.terms || {} as Terms)
    const titleCompany = computed(() => loan.value.titleCompany || {} as TitleCompany)
    const trusts = computed(() => loan.value.trusts || [])
    const underwriting = computed(() => loan.value.underwriting || {} as Underwriting)
    const underwritingSummary = computed(() => {
        const underwritingSummary = documentDatasets.value.underwritingSummary || {} as UnderwritingSummary_1008
        underwritingSummary.underwritingAgreement = underwritingSummary.underwritingAgreement || {} as UnderwritingAgreement
        underwritingSummary.underwritingAgreement.freddieMacClassification = underwritingSummary.underwritingAgreement.freddieMacClassification || {} as FreddieMacClassification
        underwritingSummary.underwritingAgreement.fannieMaeClassification = underwritingSummary.underwritingAgreement.fannieMaeClassification || {} as FannieMaeClassification
        underwritingSummary.underwritingAgreement.mortgageInformation = underwritingSummary.underwritingAgreement.mortgageInformation || {} as MortgageInformation
        underwritingSummary.underwritingAgreement.mortgageInformation.mortgageOriginatorType = underwritingSummary.underwritingAgreement.mortgageInformation.mortgageOriginatorType || {} as MortgageOriginatorType
        
        underwritingSummary.underwritingInformation = underwritingSummary.underwritingInformation || {} as UnderwritingInformation
        underwritingSummary.underwritingInformation.income = underwritingSummary.underwritingInformation.income || {} as IncomeDetails
        underwritingSummary.underwritingInformation.qualifyingRatios = underwritingSummary.underwritingInformation.qualifyingRatios || {} as QualifyingRatios
        underwritingSummary.underwritingInformation.propertyReview = underwritingSummary.underwritingInformation.propertyReview || {} as PropertyReviewLevel
        underwritingSummary.underwritingInformation.qualifyingRate = underwritingSummary.underwritingInformation.qualifyingRate || {} as QualifyingRate
        underwritingSummary.underwritingInformation.qualifyingRate.rateUsedForQualifying = underwritingSummary.underwritingInformation.qualifyingRate.rateUsedForQualifying || {} as RateSelection
        underwritingSummary.underwritingInformation.qualifyingRate.initialBoughtDownRate = underwritingSummary.underwritingInformation.qualifyingRate.initialBoughtDownRate || {} as RateSelection
        underwritingSummary.underwritingInformation.qualifyingRate.other = underwritingSummary.underwritingInformation.qualifyingRate.other || {} as RateSelection
        underwritingSummary.underwritingInformation.riskAssessment = underwritingSummary.underwritingInformation.riskAssessment || {} as RiskAssessment
        underwritingSummary.underwritingInformation.closingFunds = underwritingSummary.underwritingInformation.closingFunds || {} as ClosingFunds
        
        underwritingSummary.loanSellerInformation = underwritingSummary.loanSellerInformation || {} as LoanSellerInformation
        return underwritingSummary
    })
    const va = computed(() => loan.value.va || {} as Va)
    const vaLoanAnalysis = computed(() => documentDatasets.value.vaLoanAnalysis || {} as VaLoanAnalysis)
    const vesting = computed(() => loan.value.vesting || {} as Vesting)
    const warrantyDeed = computed(() => documentDatasets.value.warrantyDeed || {} as WarrantyDeed)
    const lockedByName = computed(() => loanLock.value?.user)
    const isLocked = computed<boolean>(() => loanLock.value != null)
    const isLockedByMe = computed(() => isLocked.value && !loanLock.value?.exclusiveOwner && lockedByName.value === userStore.currentUser?.emailAddress)
    const isLockedByOther = computed(() => isLocked.value && (!!loanLock.value?.exclusiveOwner || (!loanLock.value?.exclusiveOwner && lockedByName.value !== userStore.currentUser?.emailAddress)))
    const lockedTime = computed<string>(() => dateTimeDiffFilter(loanLock.value?.updateTime))

    // getters that were copied names of previous store state props but weren't returning their value directly
    const lastBorrowerIdGetter = computed(() => lastBorrowerId.value || 0)
    const persistGetter = computed(() => persist.value ?? userPreferenceService.getAutoSavePreference())
    const overriddenDefaultPathsGetter = computed(() => overriddenDefaultPaths.value || {} as Record<string, boolean>)

    // trid disclosure getters
    const tridDisclosures = computed(() => loan.value.tridDisclosures || [] as TridDisclosure[])
    const isMultiDisclosureMode = computed(() => tridDisclosures.value && tridDisclosures.value.length > 0)
    const tridDisclosureDetail = (index: number | undefined) => {
        return index != undefined && tridDisclosures.value[index] ? (tridDisclosures.value[index] || {} as TridDisclosure) : {} as TridDisclosure
    }
    const tridDisclosureCalculations = (index: number | undefined) => {
        return tridDisclosureDetail(index).loan?.calculations || {} as CalculatedData
    }
    const tridDisclosureCd = (index: number | undefined) => {
        return tridDisclosureDocumentDatasets(index).disclosures?.cd || {} as LoanCd
    }
    const tridDisclosureClosing = (index: number | undefined) => {
        return tridDisclosureDetail(index).loan?.closing || {} as Closing
    }
    const tridDisclosureDocumentDatasets = (index: number | undefined) => {
        return tridDisclosureDetail(index).loan?.documentDatasets || {} as DocumentDatasets
    }
    const tridDisclosureEscrow = (index: number | undefined) => {
        return tridDisclosureDetail(index).loan?.escrow || {} as Escrow
    }
    const tridDisclosureFees = (index: number | undefined) => {
        return tridDisclosureDetail(index).loan?.fees || [] as Fee[]
    }
    const tridDisclosureMortgageInsurance = (index: number | undefined) => {
        return tridDisclosureDetail(index).loan?.mortgageInsurance || {} as MortgageInsurance
    }
    const tridDisclosureTerms = (index: number | undefined) => {
        return tridDisclosureDetail(index).loan?.terms || {} as Terms
    }
    //#endregion 

    //#region ACTIONS
    // synchronous
    function clearLoanRecord() {
        loanRecord.value = null
        loanLock.value = null
    }

    function updateLoanId(id: string | undefined) {
        if (!loanRecord.value || !id) return
        loanRecord.value.id = id
        lastBorrowerId.value = null
    }

    function updateLoanRecord(updatedLoanRecord: LoanRecord | null) {
        if (updatedLoanRecord) {
            loanRecord.value = updatedLoanRecord
            loanLock.value = updatedLoanRecord.lock
            if (updatedLoanRecord.documentMode === DocumentMode.RegCheck) {
                complianceChecks.value = updatedLoanRecord.complianceResults?.results
            }
            overriddenDefaultPaths.value = cloneDeep(loanRecord.value?.data?.overriddenDefaultPaths)
        }
    }

    function updateLoanStatus(partialRecord: LoanRecord) {
        if (!loanRecord.value) return //if there's no current record to update, just bail
        loanRecord.value.audit = partialRecord.audit
        loanRecord.value.lock = partialRecord.lock //might be null, which is valid
        loanLock.value = partialRecord.lock //need this for watchers to pick up the change
    }

    function updateOverriddenDefaults({ path, isOverridden }) {
        if (!path || isOverridden === undefined) return

        if (!overriddenDefaultPaths.value)
            overriddenDefaultPaths.value = {} as Record<string, boolean>

        //Call set from vue to explicitly to update watchers that a property on the object has changed
        set(overriddenDefaultPaths.value, path, isOverridden)
    }

    function updateTridDisclosureState(request: TridDisclosurePatchRequest) {
        const tridDisclosures = loanRecord.value?.data?.tridDisclosures
        if(tridDisclosures && tridDisclosures[request.index] && loanRecord.value) {
            tridDisclosures[request.index] = request.updatedTridDisclosure
            loanRecord.value.data.tridDisclosures = [...tridDisclosures] // needs new reference to trigger getters
        }
    }

    // asynchronous
    async function getLoanRecord({ loanId, options }) {
        try {
            pageActions.setBusyPage(true)
            clearLoanRecord()
            /*
                The persist endpoint ensures any stale copies of the loan will be removed from the cache.
                This prevents the user from accidentally saving changes to an out-of-date cached record.
                The persist endpoint also returns a full copy of the loan, so calling it INSTEAD of "get" reduces traffic.
            */
            options = options || {} as LoanRequestOptions
            const loanRecord = persistGetter.value
                ? await loanService.get(loanId, options.includeCd, options.includeHoepaCheck, options.includeCompliance) // will do a plain get from loans api
                : await backendService.getPersisted(loanId, true, options.includeCd, options.includeHoepaCheck, options.includeCompliance) // will get a copy of the persisted loan with a cached id
            updateLoanRecord(loanRecord)
            if (loanRecord?.data?.lender?.info?.lenderProfileId && loanRecord?.clientCode) {
                const lenderProfileFromApi = await adminDataService.getLenderProfile(loanRecord.clientCode, loanRecord.data.lender.info.lenderProfileId)
                lenderProfile.value = lenderProfileFromApi
            } else {
                lenderProfile.value = null
            }
            await auditChecks.checkDisplayAndUpdateAuditChecks(loanRecord?.id)
        } finally {
            pageActions.setBusyPage(false)
        }
    }

    async function setLoanLock(lockRequest: LoanLockRequest) {
        try {
            await pageActions.updateSaveLoading(true, true, persistGetter.value)
            const loanId = loanRecord.value?.id
            if (loanId) {
                const partialLoanRecord = await backendService.lock(loanId, lockRequest)
                updateLoanStatus(partialLoanRecord)
            }
        } catch (err: any) {
            if (err.response?.status === 423) {
                persist.value = true //force the UI to notify the user of the error
                await ping(true)
                err.handled = true
            }
        } finally {
            await pageActions.updateSaveLoading(false, true, persistGetter.value)
        }
    }

    async function ping(overrideConcurrencyCheck = false) {
        if (!overrideConcurrencyCheck && pageActions.saveLoading.isLoading) return //we're already getting these updates from another call
        try {
            const loanId = loanRecord.value?.id
            if (loanId) {
                const partialLoanRecord = await backendService.ping(loanId)
                updateLoanStatus(partialLoanRecord)
            }
        } catch (err: any) {
            if (err.response?.status === 423) {
                persist.value = true //force the UI to notify the user of the error
                await ping(true)
                err.handled = true
            }
        } finally {
            await pageActions.updateSaveLoading(false, true, persistGetter.value)
        }
    }

    async function setLoanPersist(persistRequest: LoanPersistRequest) {
        try {
            await pageActions.updateSaveLoading(true, true, persistGetter.value)
            const loanId = loanRecord.value?.id
            if (loanId) {
                const savedLoanRecord = await backendService.getPersisted(loanId, persistRequest.requestCachedId)
                if (persistRequest.updateLoanRecord) {
                    updateLoanRecord(savedLoanRecord)
                    await auditChecks.checkDisplayAndUpdateAuditChecks(loanId)
                    componentKey.value++ //refresh my view because there could be a lot of changes
                } else {
                    updateLoanId(savedLoanRecord?.id)
                }
                persist.value = !persistRequest.requestCachedId
            }
        } finally {
            await pageActions.updateSaveLoading(false, true, persistGetter.value)
        }
    }

    async function commitLoan() {
        try {
            await pageActions.updateSaveLoading(true, true, persistGetter.value)
            const loanId = loanRecord.value?.id
            if (loanId) {
                const savedLoanRecord = await backendService.commitCacheRecord(loanId)
                updateLoanRecord(savedLoanRecord)
                await auditChecks.checkDisplayAndUpdateAuditChecks(loanId)
            }
        } catch (err: any) {
            if (err.response?.status === 423) {
                persist.value = true //force the UI to notify the user of the error
                await ping(true)
                err.handled = true
            }
        } finally {
            await pageActions.updateSaveLoading(false, true, persistGetter.value)
        }
    }

    async function patchLoanRecord({ patchData, options }) {
        try {
            if (pageActions.saveLoading.isLoading) return //prevent concurrent save operations
            isProtectedDisabled.value = true
            await pageActions.updateSaveLoading(true, true, persistGetter.value)
            const loanId = loanRecord.value?.id
            if (loanId) {
                options = options || {} as LoanRequestOptions
                const savedLoanRecord = await backendService.patch(loanId, patchData, options.includeCd, options.includeHoepaCheck, options.includeCompliance)
                updateLoanRecord(savedLoanRecord)
                await auditChecks.checkDisplayAndUpdateAuditChecks(loanId)
            }
        } catch (err: any) {
            if (err.response?.status === 423) {
                persist.value = true //force the UI to notify the user of the error
                await ping(true)
                err.handled = true
            }
        } finally {
            isProtectedDisabled.value = false
            await pageActions.updateSaveLoading(false, true, persistGetter.value)
        }
    }

    async function refreshLoanRecord(options: LoanRequestOptions) {
        try {
            await pageActions.updateSaveLoading(true, true, persistGetter.value)
            const loanId = loanRecord.value?.id
            if (loanId) {
                options = options || {} as LoanRequestOptions
                const loanRecord = await loanService.get(loanId, options.includeCd, options.includeHoepaCheck)
                updateLoanRecord(loanRecord)
                await auditChecks.checkDisplayAndUpdateAuditChecks(loanId)
            }
        } finally {
            await pageActions.updateSaveLoading(false, true, persistGetter.value)
        }
    }

    async function getComplianceChecks({ id, suiteOptions }) {
        try {
            complianceChecks.value = []
            complianceErrors.value = []
            const complianceChecksFromApi = await loanService.getCompliance(id, suiteOptions)
            complianceChecks.value = complianceChecksFromApi.results
        } catch (err: any) {
            if (err.response.status === 400) {
                complianceErrors.value = err.response.data.errorMessages
            } else {
                complianceErrors.value = ["Unable to get compliance check results."]
            }
        }
    }

    async function updateDocumentMode(request: UpdateDocumentModeRequest) {
        try {
            await pageActions.updateSaveLoading(true, true, persistGetter.value)
            const loanId = loanRecord.value?.id
            if (loanId) {
                const updatedLoanRecord = await backendService.updateDocumentMode(loanId, request)
                updateLoanRecord(updatedLoanRecord)
                await auditChecks.checkDisplayAndUpdateAuditChecks(updatedLoanRecord?.id)
            }
        } catch (err: any) {
            if (err.response?.status === 423) {
                persist.value = true //force the UI to notify the user of the error
                await ping(true)
                err.handled = true
            }
        } finally {
            await pageActions.updateSaveLoading(false, true, persistGetter.value)
        }
    }

    async function updateLenderProfile(request: UpdateLenderProfileRequest) {
        try {
            await pageActions.updateSaveLoading(true, true, persistGetter.value)
            const loanId = loanRecord.value?.id
            if (loanId) {
                const updatedLoanRecord = await loanService.updateLenderProfile(loanId, request)
                updateLoanRecord(updatedLoanRecord)
                const lenderProfileFromApi = await adminDataService.getLenderProfile(updatedLoanRecord.clientCode, updatedLoanRecord.data.lender.info.lenderProfileId);
                lenderProfile.value = lenderProfileFromApi
                await auditChecks.checkDisplayAndUpdateAuditChecks(updatedLoanRecord?.id)
            }
        } catch (err: any) {
            if (err.response?.status === 423) {
                persist.value = true //force the UI to notify the user of the error
                await ping(true)
                err.handled = true
            }
        } finally {
            await pageActions.updateSaveLoading(false, true, persistGetter.value)
        }
    }

    async function updateInvestor(request: UpdateInvestorRequest) {
        try {
            await pageActions.updateSaveLoading(true, true, persistGetter.value)
            const loanId = loanRecord.value?.id
            if (loanId) {
                const updatedLoanRecord = await loanService.updateInvestor(loanId, request)
                updateLoanRecord(updatedLoanRecord)
                await auditChecks.checkDisplayAndUpdateAuditChecks(updatedLoanRecord?.id)
            }
        } catch (err: any) {
            if (err.response?.status === 423) {
                persist.value = true //force the UI to notify the user of the error
                await ping(true)
                err.handled = true
            }
        } finally {
            await pageActions.updateSaveLoading(false, true, persistGetter.value)
        }
    }

    // trid disclosures
    async function addTridDisclosure(request: NewTridDisclosureRequest) {
        try {
            await pageActions.updateSaveLoading(true, true, persistGetter.value)
            const loanId = loanRecord.value?.id
            if (loanId) {
                const updatedLoanRecord = await tridDisclosureService.addTridDisclosure(loanId, request)
                updateLoanRecord(updatedLoanRecord)
                await auditChecks.checkDisplayAndUpdateAuditChecks(updatedLoanRecord?.id)
            }
        } catch (err: any) {
            if (err.response?.status === 423) {
                persist.value = true //force the UI to notify the user of the error
                await ping(true)
                err.handled = true
            }
        } finally {
            await pageActions.updateSaveLoading(false, true, persistGetter.value)
        }
    }

    async function deleteTridDisclosure(index: number) {
        try {
            await pageActions.updateSaveLoading(true, true, persistGetter.value)
            const loanId = loanRecord.value?.id
            if (loanId != null) {
                const updatedLoanRecord = await tridDisclosureService.deleteTridDisclosure(loanId, index)
                updateLoanRecord(updatedLoanRecord)
                await auditChecks.checkDisplayAndUpdateAuditChecks(updatedLoanRecord?.id)
            }
        } catch (err: any) {
            if (err.response?.status === 423) {
                persist.value = true //force the UI to notify the user of the error
                await ping(true)
                err.handled = true
            }
        } finally {
            await pageActions.updateSaveLoading(false, true, persistGetter.value)
        }
    }

    async function updateTridDisclosure(request: TridDisclosurePatchRequest) {
        try {
            const loanId = loanRecord.value?.id
            if (loanId) {
                const tridDisclosureFromApi = await tridDisclosureService.postTridDisclosure(loanId, request.index, request.updatedTridDisclosure)
                updateTridDisclosureState({ index: request.index, updatedTridDisclosure: tridDisclosureFromApi })
            }
        } catch (err: any) {
            console.error(err)
        }
    }
    //#endregion

    return {
        adverseAction,
        automatedUnderwriting,
        borrowers,
        broker,
        cacheCommitLoading,
        calculatedCd,
        calculatedLe,
        cd,
        clientCode,
        closing,
        compliance,
        complianceChecks,
        complianceErrors,
        componentKey,
        defaultedFields,
        disclosures,
        documentDatasets,
        documentMode,
        existingLoan,
        escrow,
        escrowHoldback,
        fees,
        fha,
        guarantors,
        hasShownLockedModal,
        housingExpenses,
        includeOnlyBorrowerFeesLe,
        investor,
        isLocked,
        isLockedByMe,
        isLockedByOther,
        isModification,
        isMultiDisclosureMode,
        isProtectedDisabled,
        isReleaseOfLien,
        lastBorrowerId,
        lastBorrowerIdGetter,
        lateFee,
        lender,
        lenderProfile,
        loan,
        loanDocumentOptions,
        loanEstimate,
        loanGridQuery,
        loanInCdMode,
        loanInLeMode,
        loanLock,
        loanNumber,
        loanRecord,
        loanRecordId,
        loanRecordCalculations,
        lockedByName,
        lockedTime,
        mortgageInsurance,
        notary,
        originator,
        overriddenDefaultPaths,
        overriddenDefaultPathsGetter,
        persist,
        persistGetter,
        prepaymentPenalty,
        previewDocument,
        property,
        releaseOfLien,
        securityInstrument,
        seller,
        serviceProviders,
        settlementAgent,
        showLockedModal,
        showLockForcedModal,
        stateSpecific,
        tangibleNetBenefits,
        temp,
        terms,
        titleCompany,
        trusts,
        underwriting,
        underwritingSummary,
        va,
        vaLoanAnalysis,
        vesting,
        warrantyDeed,

        tridDisclosures,
        tridDisclosureCalculations,
        tridDisclosureCd,
        tridDisclosureClosing,
        tridDisclosureDetail,
        tridDisclosureDocumentDatasets,
        tridDisclosureEscrow,
        tridDisclosureFees,
        tridDisclosureMortgageInsurance,
        tridDisclosureTerms,

        commitLoan,
        getComplianceChecks,
        getLoanRecord,
        patchLoanRecord, 
        ping,
        refreshLoanRecord,
        setLoanLock,
        setLoanPersist,
        updateDocumentMode,
        updateInvestor,
        updateLenderProfile,
        updateOverriddenDefaults,
        
        addTridDisclosure,
        deleteTridDisclosure,
        updateTridDisclosure
    }
})