import { VaStateFeeDeviation } from '@/regcheck/models'
import { AdminTrustees, LiabilityAfterForeclosure, RuleBasedClosingCondition } from '.'
import { MonthlyEscrowRoundingType, UpfrontRoundingType } from './client';
import { CreditAgency, DocPrepAttorney, FeeSections, PreparedBy } from '@/common/models'

export interface SystemConfig {
    formBrand: string
    calculations: SystemCalculationsConfiguration
    feeDefaults: FeeDefaults
    security: SystemConfigSecurity
    trustees: AdminTrustees[]
    docPrepAttorneys: DocPrepAttorney[]
    preparedBy: PreparedBy[]
    creditAgencies: CreditAgency[]
    closingConditions: RuleBasedClosingCondition[]
    liabilityAfterForeclosure: LiabilityAfterForeclosure
    vaStateFeeDeviations: VaStateFeeDeviation[]
    loanReview: LoanReview
    fulfillments: Fulfillment[]
    mersAcknowledgment: MersAcknowledgment
}

export interface FeeDefaults {
    fees: AprFeeReference[]
    prepaids: AprFeeReference[]
    escrows: FeeReference[]
    housingExpenses: FeeReference[]
    feeSections: FeeSections
}

export interface FeeReference {
    type: string
    displayName: string
}

export interface LoanReview {
    email: string
}

export interface MersAcknowledgment {
    beneficiaryName: string
    type: string
    typeOtherDescription: string
    stateOfIncorporation: string
}

export interface AprFeeReference extends FeeReference{
    aprSettings: FeeAprConfiguration[]
}

export interface FeeAprConfiguration{
    paidByTypes?: string[]
    paidToTypes?: string[]
    includedInApr: boolean
    configurable: boolean
}

export interface SystemConfigSecurity {
    passwordExpiration: number
    sessionTimeout: number
    characters: SecurityChar
}

export interface SecurityChar {
    minimum: number
    uppercase: number
    lowercase: number
    numeric: number
    special: number
}

export interface SystemCalculationsConfiguration {
    monthlyEscrowRoundingType: MonthlyEscrowRoundingType
    upfrontRoundingType: UpfrontRoundingType
}

export interface GeneralSystemConfig {
    formBrand: string
    liabilityAfterForeclosure: LiabilityAfterForeclosure
    loanReview: LoanReview
}

export interface Fulfillment {
    packageTypeCodes: string[]
    fulfillmentDays: number
    dateType: FulfillmentDateType
}

export enum FulfillmentDateType {
    ApplicationDate = "ApplicationDate"
}