<template>
    <div class="field-section">
        <!-- update to take sizing parameter and use new input control -->
        <div class="field-group">
            <fg-text
                :id="`${id}-street`"
                label="Street"
                size="m"
                v-model="vm.street"
                v-if="street && address"
                :jsonPath="fullJsonPath"
                jsonProperty="street"
                :disabled="readOnly"
                :protected="protected"
                :protectedBy="protectedBy"
                @change="$emit('change', $event)"
            />
            <fg-text
                :id="`${id}-unit`"
                label="Unit #"
                size="xs"
                v-model="vm.unit"
                v-if="unit && address"
                :jsonPath="fullJsonPath"
                jsonProperty="unit"
                :disabled="readOnly"
                :protected="protected"
                :protectedBy="protectedBy"
                @change="$emit('change', $event)"
            />
        </div>
        <div class="field-group">
            <fg-text
                :id="`${id}-city`"
                label="City"
                size="s"
                v-model="vm.city"
                v-if="city && address"
                :jsonPath="fullJsonPath"
                jsonProperty="city"
                :disabled="readOnly"
                :protected="protected"
                :protectedBy="protectedBy"
                @change="$emit('change', $event)"
            />
            <fg-select
                :id="`${id}-state`"
                label="State"
                size="xs"
                v-model="vm.state"
                v-if="state && address"
                :jsonPath="fullJsonPath"
                jsonProperty="state"
                referenceType="states"
                :disabled="readOnly"
                :protected="protected"
                :protectedBy="protectedBy"
                @change="$emit('change', $event)"
            />
            <fg-input-pattern-mask
                pattern="zip"
                :id="`${id}-zip`"
                label="Zip"
                size="s"
                v-model="vm.zip"
                v-if="zip && address"
                :jsonPath="fullJsonPath"
                jsonProperty="zip"
                :disabled="readOnly"
                :protected="protected"
                :protectedBy="protectedBy"
                @change="$emit('change', $event)"
            />
            <fg-text
                :id="`${id}-county`"
                label="County"
                size="s"
                v-model="vm.county"
                v-if="county && address"
                :jsonPath="fullJsonPath"
                jsonProperty="county"
                :disabled="readOnly"
                :protected="protected"
                :protectedBy="protectedBy"
                :help-text="countyHelpText"
                @change="$emit('change', $event)"
            />
            <fg-text
                :id="`${id}-country`"
                label="Country"
                size="s"
                v-model="vm.country"
                v-if="country && address"
                :jsonPath="fullJsonPath"
                jsonProperty="country"
                :disabled="readOnly"
                :protected="protected"
                :protectedBy="protectedBy"
                @change="$emit('change', $event)"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { Address, ReadonlyPermissions } from '@/common/models'
import { PropType, computed, defineProps, toRef } from 'vue'
    
//#region DEFINE VARIABLES
const props = defineProps({
    address: { type: Object as PropType<Address>, required: true },
    id: { type: String, required: true },
    readOnly: { type: Boolean },
    protected: { type: Boolean },
    street: { type: Boolean },
    unit: { type: Boolean },
    city: { type: Boolean },
    state: { type: Boolean },
    zip: { type: Boolean },
    county: { type: Boolean },
    countyHelpText: { type: String },
    country: { type: Boolean },
    protectedBy: { type: String as PropType<ReadonlyPermissions>, default: "" },
    jsonPath: { type: String, default: "" },
    jsonProperty: { type: String, default: null },
})

const vm = toRef(props, "address")
//#endregion

//#region COMPUTED
const fullJsonPath = computed(() => {
    if (props.jsonProperty) 
        return props.jsonPath + (props.jsonProperty[0] === '.' ? props.jsonProperty : '.' + props.jsonProperty)
    else 
        return props.jsonPath
})
//#endregion
</script>